import {SUCCEED_FETCH_TOKEN} from 'app/store/actions/tokenAction';
import {
    ADD_CONFIGURE_STYLE_FINISHED,
    CANCEL_CONFIGURE_STYLE_FINISHED,
    DELETE_CONFIGURE_STYLE_FINISHED,
    EDIT_CONFIGURE_STYLE,
    SAVE_CONFIGURE_STYLE,
    SET_SHOW_SAVE_MODAL,
    SET_SHOW_DELETE_MODAL,
    SET_STYLE_ALPHA,
    SET_STYLE_COLOR,
    SET_STYLE_LABEL,
    SET_STYLE_OUTLINE_COLOR,
    SET_SYMBOL_STYLE,
} from 'app/store/actions/configureDataSymbolsAction';
import {FETCH_FEATURE_FLAGS} from 'app/store/actions/launchDarklyAction';

import {updateItemThatMatchCriteria, deleteItemById} from 'utils/reducerUtil';
import {Record} from 'immutable';

const StyleRecord = Record({
    id: null,
    styleName: null,
    styleLabel: null,
    styleDescription: null,
    shapeType: null,
    symbolStyle: null,
    outlineStyle: null,
    colour: null,
    outlineColour: null,
    size: null,
    alpha: null,
    outlineAlpha: null,
    disabled: true,
    tempStorage: {
        styleLabel: null,
        colour: null,
        symbolStyle: null,
        alpha: null,
    },
}, 'StyleRecord');

export const INITIAL_STATE = {
    organisationStyles: {
        data: [],
        selectedStyleId: null,
        showSaveModal: false,
        showDeleteModal: false,
    },
    parentNCBId: null,
    supportedFeatures: [],
    featureFlags: {},
};

export default function reducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
    case ADD_CONFIGURE_STYLE_FINISHED:
        return {
            ...state,
            organisationStyles: {
                ...state.organisationStyles,
                data: [
                    ...state.organisationStyles.data,
                    new StyleRecord({...action.payload, alpha: 0, disabled: false}).toJS(),
                ],
                selectedStyleId: action.payload.id,
            },
        };
    case CANCEL_CONFIGURE_STYLE_FINISHED: {
        const id = action.payload;
        const editedStyle = state.organisationStyles.data.find((style) => style.id === id);

        return {
            ...state,
            organisationStyles: {
                ...state.organisationStyles,
                data: updateItemThatMatchCriteria(state.organisationStyles.data, {id}, {
                    disabled: true,
                    alpha: editedStyle.tempStorage.alpha,
                    colour: editedStyle.tempStorage.colour,
                    outlineColour: editedStyle.tempStorage.outlineColour,
                    styleLabel: editedStyle.tempStorage.styleLabel,
                    symbolStyle: editedStyle.tempStorage.symbolStyle,
                    tempStorage: {
                        alpha: null,
                        colour: null,
                        outlineColour: null,
                        styleLabel: null,
                        symbolStyle: null,
                    },
                }),
            },
        };
    }
    case DELETE_CONFIGURE_STYLE_FINISHED:
        return {
            ...state,
            organisationStyles: {
                ...state.organisationStyles,
                data: deleteItemById([...state.organisationStyles.data], action.payload),
            },
        };
    case EDIT_CONFIGURE_STYLE: {
        const id = action.payload;
        const editedStyle = state.organisationStyles.data.find((style) => style.id === id);

        return {
            ...state,
            organisationStyles: {
                ...state.organisationStyles,
                data: updateItemThatMatchCriteria(state.organisationStyles.data, {id}, {
                    disabled: false,
                    tempStorage: {
                        alpha: editedStyle.alpha,
                        colour: editedStyle.colour,
                        outlineColour: editedStyle.outlineColour,
                        styleLabel: editedStyle.styleLabel,
                        symbolStyle: editedStyle.symbolStyle,
                    },
                }),
                selectedStyleId: id,
            },
        };
    }
    case SAVE_CONFIGURE_STYLE: {
        const id = action.payload;
        return {
            ...state,
            organisationStyles: {
                ...state.organisationStyles,
                data: updateItemThatMatchCriteria(state.organisationStyles.data, {id}, {disabled: true}),
            },
        };
    }
    case SET_SHOW_DELETE_MODAL:
        return {
            ...state,
            organisationStyles: {
                ...state.organisationStyles,
                showDeleteModal: !state.organisationStyles.showDeleteModal,
                selectedStyleId: !state.organisationStyles.showDeleteModal ? action.payload : null,
            },
        };
    case SET_SHOW_SAVE_MODAL:
        return {
            ...state,
            organisationStyles: {
                ...state.organisationStyles,
                showSaveModal: !state.organisationStyles.showSaveModal,
                selectedStyleId: !state.organisationStyles.showSaveModal ? state.organisationStyles.selectedStyleId : null,
            },
        };
    case SET_STYLE_ALPHA: {
        const {id, alpha} = action.payload;
        return {
            ...state,
            organisationStyles: {
                ...state.organisationStyles,
                data: updateItemThatMatchCriteria(state.organisationStyles.data, {id}, {alpha}),
            },
        };
    }
    case SET_STYLE_COLOR: {
        const {id, colour} = action.payload;
        return {
            ...state,
            organisationStyles: {
                ...state.organisationStyles,
                data: updateItemThatMatchCriteria(state.organisationStyles.data, {id}, {colour}),
            },
        };
    }
    case SET_STYLE_LABEL: {
        const {id, styleLabel} = action.payload;
        return {
            ...state,
            organisationStyles: {
                ...state.organisationStyles,
                data: updateItemThatMatchCriteria(state.organisationStyles.data, {id}, {styleLabel}),
            },
        };
    }
    case SET_STYLE_OUTLINE_COLOR: {
        const {id, outlineColour} = action.payload;
        return {
            ...state,
            organisationStyles: {
                ...state.organisationStyles,
                data: updateItemThatMatchCriteria(state.organisationStyles.data, {id}, {outlineColour}),
            },
        };
    }
    case SET_SYMBOL_STYLE: {
        const {id, symbolStyle} = action.payload;
        return {
            ...state,
            organisationStyles: {
                ...state.organisationStyles,
                data: updateItemThatMatchCriteria(state.organisationStyles.data, {id}, {symbolStyle}),
            },
        };
    }
    case SUCCEED_FETCH_TOKEN: {
        const organisationStyles = action.payload.claudToken.config.organisationStyles.reduce((acc, style) => {
            return [...acc, new StyleRecord({...style}).toJS()];
        }, []);

        return {
            ...state,
            ...action.payload.claudToken.config,
            organisationStyles: {
                ...state.organisationStyles,
                data: organisationStyles,
            },
        };
    }
    case FETCH_FEATURE_FLAGS:
        return {
            ...state,
            featureFlags: { ...action.payload }
        };
    default:
        return state;
    }
}
