import {Map, Record} from 'immutable';
import {
    CHANGE_ACTIVE_TOOL,
    CHANGE_ACTIVE_TOOL_FAILED,
    DELETE_GRAPHIC_ATTRIBUTES_FAILED,
    RESET_GRAPHIC_ATTRIBUTES,
    SET_CUSTOM_TYPE_GRAPHIC_ATTRIBUTE,
    SET_GRAPHIC_ATTRIBUTE,
    SET_GRAPHIC_ATTRIBUTES,
    SUBMIT_GRAPHIC_ATTRIBUTES_FAILED,
} from 'app/store/actions/addEditDrawAndMeasureAction';

const SelectedGraphicToolDetails = Record({
    objectid: null,
    city: null,
    contact_person: null,
    contact_details: null,
    date1: null,
    description: null,
    note1: null,
    po_box: null,
    reference: null,
    st_num: null,
    street: null,
    style: null,
    suburb: null,
    title: null,
    value1: null,
}, 'SelectedGraphicToolDetails');

export const INITIAL_STATE = Map({
    activeTool: null,
    activeToolErrorMsg: null,
    deleteErrorMsg: null,
    submitErrorMsg: null,
    selectedGraphicToolDetails: new SelectedGraphicToolDetails(),
});

export default function reducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
    case CHANGE_ACTIVE_TOOL:
        return state.set('activeTool', action.payload);
    case CHANGE_ACTIVE_TOOL_FAILED:
        return state.set('activeToolErrorMsg', action.payload);
    case DELETE_GRAPHIC_ATTRIBUTES_FAILED:
        return state.set('deleteErrorMsg', action.payload);
    case RESET_GRAPHIC_ATTRIBUTES:
        return state.update('selectedGraphicToolDetails', (details) => {
            for (const detail in details.toJS()) { // eslint-disable-line
                details = details.set(detail, null);
            }
            return details;
        });
    case SET_CUSTOM_TYPE_GRAPHIC_ATTRIBUTE:
        return state.setIn(['selectedGraphicToolDetails', 'style'], action.payload);
    case SET_GRAPHIC_ATTRIBUTE:
        return state.setIn(['selectedGraphicToolDetails', action.payload.name], action.payload.value);
    case SET_GRAPHIC_ATTRIBUTES:
        return state.set('activeTool', action.payload.activeTool)
            .update('selectedGraphicToolDetails', (details) => {
            for (const attribute in action.payload.attributes) { // eslint-disable-line
                    details = details.set(attribute, action.payload.attributes[attribute]);
                }
                return details;
            });
    case SUBMIT_GRAPHIC_ATTRIBUTES_FAILED:
        return state.set('submitErrorMsg', action.payload);
    default:
        return state;
    }
}