import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Button} from 'semantic-ui-react';
import './BasemapButtons.css';
import {STREET_BASEMAP, HYBRID_BASEMAP, AERIAL_BASEMAP, NEARMAP} from 'app/api/configs/mapConfig';
import {getFeatureFlags} from 'app/store/sagas/launchDarklySaga';

const BasemapButtons = ({selectedBasemap, onSelectBasemap}) => {
    const featureFlags = useSelector(getFeatureFlags);

    React.useEffect(() => {
        // should reset basemap to STREET_BASEMAP after featureflag is turned off
        if (!featureFlags.showEmapNearmapButton && selectedBasemap === NEARMAP) {
            onSelectBasemap(STREET_BASEMAP);
        }
    }, [featureFlags]);

    return (
        <Button.Group className="basemap-buttons" id="basemap-buttons">
            <Button
                active={selectedBasemap === STREET_BASEMAP}
                className="basemap-buttons__button basemap-buttons__street"
                onClick={() => onSelectBasemap(STREET_BASEMAP)}

            >
                Street
            </Button>
            <Button
                active={selectedBasemap === HYBRID_BASEMAP}
                className="basemap-buttons__button basemap-buttons__hybrid"
                onClick={() => onSelectBasemap(HYBRID_BASEMAP)}
            >
                Hybrid
            </Button>
            <Button
                active={selectedBasemap === AERIAL_BASEMAP}
                className="basemap-buttons__button basemap-buttons__aerial"
                onClick={() => onSelectBasemap(AERIAL_BASEMAP)}
            >
                Aerial
            </Button>
            {featureFlags.showEmapNearmapButton ? <Button
                active={selectedBasemap === NEARMAP}
                className="basemap-buttons__button basemap-buttons__nearmap"
                onClick={() => onSelectBasemap(NEARMAP)}
            >
                Nearmap
            </Button> : null}
        </Button.Group>
    );
};

BasemapButtons.propTypes = {
    selectedBasemap: PropTypes.string,
    onSelectBasemap: PropTypes.func.isRequired,
};

export default BasemapButtons;