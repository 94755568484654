import {connect} from 'react-redux';
import AddEditAttributes from 'app/components/RightBarPane/AddEditAttributes';
import {submitGraphicAttributes, deleteGraphicAttributes} from 'app/store/actions/addEditDrawAndMeasureAction';
import {convertOxNotationToHex} from 'utils';

export const MERIDIAN_CORPORATION = 'Meridian Energy Ltd';
export const extractOrganisationStylesByShapeType = (state) => {
    const activeTool = state.getIn(['addEditDrawAndMeasure', 'activeTool']);

    return state.getIn(['config', 'organisationStyles', 'data'])
        .filter(({shapeType}) => (
            (shapeType === 'poly' && ['circle', 'parcel', 'polygon'].includes(activeTool)) ||
        (shapeType === 'line' && ['line', 'polyline'].includes(activeTool)) ||
        (shapeType === activeTool)
        ))
        .sort((s1, s2) => s1.styleLabel > s2.styleLabel ? 1 : -1)
        .map((symbol) => ({
            id: symbol.id,
            color: convertOxNotationToHex(symbol.colour),
            outlineColor: convertOxNotationToHex(symbol.outlineColour),
            shapeType: symbol.shapeType,
            symbolStyle: symbol.symbolStyle,
            text: symbol.styleLabel,
            value: symbol.styleName !== 'Default' ? symbol.styleName : null,
        }));
};

export const mapStateToProps = (state, extractOrganisationStylesByShapeTypeLocal = extractOrganisationStylesByShapeType) => ({
    customTypeOptions: extractOrganisationStylesByShapeTypeLocal(state),
    isDeleteDisabled: !state.getIn(['addEditDrawAndMeasure', 'selectedGraphicToolDetails', 'objectid']),
    isMeridianUser: state.getIn(['config', 'corporation']) === MERIDIAN_CORPORATION,
});

export const mapDispatchToProps = (dispatch) => ({
    onSubmitGraphicAttributes: () => dispatch(submitGraphicAttributes()),
    onDeleteGraphicAttributes: () => dispatch(deleteGraphicAttributes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditAttributes);