import {call, put, takeLatest, select, delay, take, cancel, fork} from 'redux-saga/effects';
import {
    ATTEMPT_FETCH_TOKEN,
    ATTEMPT_LOGOUT,
    SUCCEED_LOGOUT,
    ATTEMPT_REFRESH_TOKEN,
    SET_REFRESH_TOKEN_TIMER,
    CLEAR_REFRESH_TOKEN_TIMER,
    setToken,
    setTokenFetchError,
    setLogoutRedirect,
    attemptRefreshToken,
    setRefreshTokenTimer,
    clearRefreshTokenTimer,
} from 'app/store/actions/tokenAction';
import {toggleModal, TOKEN_ERROR_MODAL} from 'app/store/actions/uiAction';
import {apiGet} from 'utils/http';
import {push} from 'connected-react-router';
import moment from 'moment';
import segmentAnalytics from 'app/store/segmentAnalytics';
import {configureStore} from 'app/store/configureStore';

// UTILS //
export const fetchToken = async (code, isAdmin) => {
    const loginUrl = isAdmin ? '/api/admin/login' : '/api/login';
    const redirectUri = isAdmin ? `${window.location.origin}/admin-site/` : `${window.location.origin}/`;
    return await apiGet({path: `${loginUrl}?code=${code}&redirectUri=${redirectUri}`});
};
export const requestNewToken = async (code) => {
    const redirectUri = `${window.location.origin}/`;
    return await apiGet({path: `/api/auth/refreshToken?code=${code}&redirectUri=${redirectUri}`});
};

export const getCode = (state) => state.getIn(['token', 'code']);
export const getLogoutRedirectUrl = (state) => state.getIn(['token', 'logoutRedirectUrl']);
export const getClaudToken = (state) => state.getIn(['token', 'claudToken']);
export const parseClaudToken = (claudToken) => JSON.parse(atob(claudToken.access_token.split('.')[1]));

export const getTokenContents = (state) => state.getIn(['token', 'tokenContents']);
export const getTokenTimeout = (tokenContents) => {
    const expiryTime = moment.unix(tokenContents.exp);
    // returns value in milliseconds
    return expiryTime.diff(moment());
};

const MINUTES_BEFORE_REFRESH = 5;
const ONE_MINUTE = 60 * 1000; // 1 minute

// WORKERS
export function* refreshTimer() {
    yield call(checkRefreshTokenFlow);
    yield delay(ONE_MINUTE);

    yield call(refreshTimer);
}

export function* checkRefreshTokenFlow() {
    const tokenContents = yield select(getTokenContents);

    if (tokenContents && tokenContents.exp) {
        const timeout = yield call(getTokenTimeout, tokenContents);
        const remainingMins = timeout / ONE_MINUTE;

        // request a new token 5 minutes BEFORE the token expires
        if (remainingMins < MINUTES_BEFORE_REFRESH) {
            yield put(attemptRefreshToken());
        }
    }
}

export function* doRefreshToken() {
    yield put(clearRefreshTokenTimer());

    const {refreshToken} = yield select(getClaudToken);

    if (refreshToken) {
        try {
            const claudToken = yield call(requestNewToken, refreshToken);
            const tokenContents = yield call(parseClaudToken, claudToken);

            yield put(setToken({
                admin: claudToken.admin && claudToken.admin === 'true',
                claudToken,
                tokenContents,
            }));
            yield put(setRefreshTokenTimer());
        } catch (e) {
            yield put(setTokenFetchError(e));
            yield put(toggleModal(TOKEN_ERROR_MODAL));
        }
    }
}

export function* fetchClaudToken({payload}) {
    yield put(clearRefreshTokenTimer());

    try {
        const claudToken = yield call(fetchToken, payload.code, payload.isAdmin);
        const replaceUrl = payload.isAdmin ? '/administration' : '/map';
        const tokenContents = yield call(parseClaudToken, claudToken);

        const responsePayload = {
            admin: claudToken.admin && claudToken.admin === 'true',
            claudToken,
            tokenContents,
        };

        yield call(segmentAnalytics.identify, claudToken.config.loginName);
        yield put(setToken(responsePayload));
        yield put(setRefreshTokenTimer());
        yield put(push(replaceUrl));
    } catch (e) {
        yield put(setTokenFetchError(e));
        yield put(toggleModal(TOKEN_ERROR_MODAL));
    }
}

export function* redirectToLogin() {
    const logoutRedirect = yield select(getLogoutRedirectUrl);
    const {persistor} = configureStore();
    yield call(persistor.purge);
    yield put(push(logoutRedirect));
}

export function* logout() {
    yield put(clearRefreshTokenTimer());

    const loc = window.location.href;
    const redirectUrl = loc.includes('/administration') || loc.includes('/admin-site') ? '/admin-site' : '/';
    yield put(setLogoutRedirect(redirectUrl));
    yield put(push('/logout'));
}

// WATCHER //
export default function* watchToken() {
    yield takeLatest(ATTEMPT_FETCH_TOKEN, fetchClaudToken);
    yield takeLatest(SUCCEED_LOGOUT, redirectToLogin);
    yield takeLatest(ATTEMPT_LOGOUT, logout);
    yield takeLatest(ATTEMPT_REFRESH_TOKEN, doRefreshToken);

    while (yield take(SET_REFRESH_TOKEN_TIMER)) {
        const task = yield fork(refreshTimer);

        yield take(CLEAR_REFRESH_TOKEN_TIMER);
        yield cancel(task);
    }
}
