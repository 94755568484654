import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { useLDClient, useFlags } from 'launchdarkly-react-client-sdk';

import HeaderContainer from 'app/containers/Header/HeaderContainer';
import MapView from 'app/components/Map/MapView';
import FooterContainer from 'app/containers/Footer/FooterContainer';
import {loadBasemap} from 'app/store/actions/mapAction';
import { fetchFeatureFlags } from 'app/store/actions/launchDarklyAction';

import './HomePage.css';

export const HomePage = ({onLoadMap, loginName, fetchFeatureFlags}) => {
    const ldClient = useLDClient();
    const flags = useFlags();
    
    React.useEffect(() => {
        onLoadMap();
    }, [onLoadMap]);
    
    React.useEffect(() => {
        ldClient.identify({ key: loginName });
    }, [ldClient, loginName]);

    React.useEffect(() => {
        fetchFeatureFlags(flags);
    }, [flags, fetchFeatureFlags]);

    return (
        <div className="home-page">
            <HeaderContainer/>
            <MapView />
            <FooterContainer/>
        </div>
    );
};

HomePage.propTypes = {
    onLoadMap: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
    const loginName = state.getIn(['config', 'loginName']);

	return {
		loginName,
	};
};

export const mapDispatchToProps = (dispatch) => ({
    onLoadMap: () => dispatch(loadBasemap()),
    fetchFeatureFlags: (flags) => dispatch(fetchFeatureFlags(flags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)