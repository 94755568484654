import {eventChannel} from 'redux-saga';
import {all, takeLatest, call, select, put} from 'redux-saga/effects';
import {refreshLayersFinished, REFRESH_LAYERS, TOGGLE_MAP_LAYER} from 'app/store/actions/layerAction';
import {LOAD_NAVIGATION_TOOLS, showNotificationToast} from 'app/store/actions/uiAction';

import {
    ADD_EDIT_YOUR_DATA_GRAPHICS_LAYER,
    getAddEditDrawAndMeasureActiveTool,
    getOrganisationStyles,
    getSymbol,
    queryFeatures,
} from 'app/store/sagas/map/addEditDrawAndMeasureSaga';
import {
    POLY,
    DRAW_AND_MEASURE_GRAPHICS_LAYER,
    addManyGraphicsToGraphicsLayer,
    removeAllGraphicsLayerGraphics,
} from 'app/store/sagas/map/drawAndMeasureSaga';
import {EXPORT_GRAPHICS_LAYER} from 'app/store/sagas/map/exportAndImportSaga';
import {PROPERTY_GRAPHICS_LAYER} from 'app/store/sagas/propertySaga';
import {getParentNCBId} from 'app/store/sagas/map/uiSaga';
import {
    loadESRITileLayer,
    loadESRIGraphicsLayer,
    loadESRIFeatureLayer,
    loadESRIDynamicLayerTemplate,
    loadESRIGeometryEngine,
    loadESRIGeometryService,
    loadESRIGraphic,
} from 'app/api/esri';
import {
    AERIAL_OVERLAY_CL,
    DYNAMIC_LAYER,
    FEATURE_LAYER,
    TILE_LAYER,
    YOUR_POLYGONS,
} from 'app/api/configs/layersConfig';
import {GEOMETRY_SERVICE_URL} from 'app/api/configs/mapConfig';
import {convertOxNotationToHex} from 'utils';

import trimEnd from 'lodash/trimEnd';
import uniq from 'lodash/uniq';
import intersection from 'lodash/intersection';

// UTILS //
export const TOOL_LAYER_MANAGEMENT = 'layer-management';
export const LABELS_GRAPHICS_LAYER = 'LABELS_GRAPHICS_LAYER';
export const addLayerToMap = (mapView, layer) => mapView.map.add(layer);
export const addManyLayerToMap = (mapView, layers) => mapView.map.addMany(layers);
export const buildLayerURL = (url, layer) => url.replace(`{${layer.urlParamKey}}`, layer.urlParamValue);
export const findLayerById = (id, mapView) => mapView.map.findLayerById(id);
export const hideLayer = (layer) => layer.visible = false;
export const removeLayerFromMap = (mapView, layer) => mapView.map.remove(layer);
export const removeMultipleLayersFromMap = (mapView, layerIds) => {
    const layersToRemove = mapView.map.layers.items.filter((l) => layerIds.includes(l.id));
    return mapView.map.removeMany(layersToRemove);
};
export const reorderLayer = (mapView, layerKey, index) => {
    const layer = mapView.map.findLayerById(layerKey);

    if (layer) {
        return mapView.map.reorder(layer, index);
    }
};
export const refreshLayer = (layer) => layer.refresh();
export const showLayer = (layer) => layer.visible = true;
export const getFeatureLayerView = async (featureLayer, mapView) => await mapView.whenLayerView(featureLayer);
export const getGroupLayers = (state) => state.getIn(['layers', 'groupLayers']);
export const getIntersectGeometries = async (geometryEngine, geometries, extent) => await geometryEngine.intersect(geometries, extent);
export const getLabelPoints = async (geometryService, geometries) => await geometryService.labelPoints(geometries);
export const getLayerFromState = (state, layerKey) => state.getIn(['layers', 'groupLayers', layerKey]);
export const getLayerIds = (layerMap) => layerMap.reduce((acc, sub) => [...acc, sub.layerKey], []);
export const getSupportedFeatures = (state) => state.getIn(['config', 'supportedFeatures']);
export const getSelectedSublayers = (state, parentLayerKey) => {
    return state.getIn(['layers', 'groupLayers']).filter((sub) => sub.isCheckbox && sub.selected && sub.parentLayerKey === parentLayerKey);
};

const extractSelectedLayers = (subLayers, state) => {
    return subLayers.reduce((acc, layer) => {
        if (layer.subLayers.size > 0) {
            const subLayersArray = layer.subLayers.toArray();

            for (let index = 0; index < subLayersArray.length; index++) {
                const subLayer = getLayerFromState(state, subLayersArray[index]);

                if (subLayer.selected && subLayer.subLayers.size > 0) {
                    const multiSublayers = getSelectedSublayers(state, subLayer.layerKey);
                    acc = [...acc, ...extractSelectedLayers(multiSublayers, state)];
                }

                if (subLayer.isCheckbox && subLayer.selected && subLayer.urlParamKey) {
                    acc = [...acc, subLayer];
                }
            }
            return acc;
        } else {
            return [...acc, layer];
        }
    }, []);
};

export const getNestedDynamicLayers = (state, parentLayerKey) => {
    const subLayers = getSelectedSublayers(state, parentLayerKey);
    return extractSelectedLayers(subLayers, state);
};

export const getUniqueURLParamKeys = (sublayers) => {
    const urlKeys = sublayers.reduce((acc, item) => [...acc, item.get('urlParamKey')], []);
    return uniq(urlKeys);
};

export const buildLayerURLParams = (url, sublayers) => {
    const urlParamKeys = getUniqueURLParamKeys(sublayers);

    return urlParamKeys.reduce((acc, key) => {
        const paramValues = sublayers.filter((s) => s.urlParamKey === key)
            .reduce((arr, s) => [...arr, s.urlParamValue], [])
            .toString();

        return acc.replace(`{${key}}`, paramValues);
    }, url);
};

export const createDefinitionExpression = (subLayers) => {
    let definition = '';
    if (subLayers.size > 0) {
        subLayers.forEach(({styleName}) => {
            if (styleName === 'Default') {
                definition += 'STYLE is NULL OR ';
            } else {
                definition += `STYLE = '${styleName}' OR `;
            }
        });
    } else {
        definition = 'STYLE = \'noSelected\' OR ';
    }

    definition = trimEnd(definition, ' OR ');
    return (definition ? `(${definition})` : '');
};

export const getTopmostParentLayer = (state, layer) => {
    if (layer.parentLayerKey) {
        const parentLayer = getLayerFromState(state, layer.parentLayerKey);
        return getTopmostParentLayer(state, parentLayer);
    }

    return layer;
};

export const getRendererByShapeType = (organisationStyles, shapeType) => {
    const uniqueValueInfos = organisationStyles
        .filter((style) => style.shapeType === shapeType)
        .map((style) => ({
            value: style.styleName === 'Default' ? '' : style.styleName,
            symbol: getSymbol(style),
        }));

    return {
        type: 'unique-value',
        field: 'STYLE',
        uniqueValueInfos,
    };
};

export const watchLayerViewUpdatingChannel = ([layerView, ...args], eventChannelLocal = eventChannel) => {
    return eventChannelLocal((emit) => {
        const watchUpdatingCallback = () => emit([layerView, ...args]);
        const handle = layerView.watch('updating', watchUpdatingCallback);
        return () => handle.remove();
    });
};

// WORKERS //
export function* handleTileLayer(mapView, layerRecord, layerFromMap) {
    if (layerFromMap) {
        yield call(removeLayerFromMap, mapView, layerFromMap);
    } else {
        const tlOptions = {
            url: layerRecord.urlTemplate,
            id: layerRecord.layerKey,
        };
        const mapLayer = yield call(loadESRITileLayer, tlOptions);
        yield call(addLayerToMap, mapView, mapLayer);
    }
}

export function* handleDynamicLayer(mapView, layerRecord) {
    const parentLayer = yield select(getTopmostParentLayer, layerRecord);
    const {layerKey: parentLayerKey, loadWithoutSelectedSublayers, selected} = parentLayer;

    const layerFromMap = yield call(findLayerById, parentLayerKey, mapView);

    // if parentLayer is selected, build URL
    if (selected) {
        const selectedSublayers = yield select(getNestedDynamicLayers, parentLayerKey);
        if (selectedSublayers.length > 0 || loadWithoutSelectedSublayers) {
            yield call(loadDynamicLayer, parentLayer, selectedSublayers, layerFromMap, mapView);
        } else {
            yield call(removeLayerFromMap, mapView, layerFromMap);
        }
    } else {
        yield call(removeLayerFromMap, mapView, layerFromMap);
    }
}

export function* handleFeatureLayer(mapView, layerFromState) {
    const parentNCBId = yield select(getParentNCBId);
    const layerKey = layerFromState.parentLayerKey || layerFromState.layerKey;
    const parentLayer = yield select(getLayerFromState, layerKey);
    const subLayers = yield select(getSelectedSublayers, layerKey);
    const featureLayer = yield call(findLayerById, layerKey, mapView);
    const polygonLabels = yield call(findLayerById, LABELS_GRAPHICS_LAYER, mapView);

    const definition = yield call(createDefinitionExpression, subLayers, parentNCBId);
    featureLayer.set('definitionExpression', definition);

    if (!parentLayer.selected) {
        yield call(hideLayer, featureLayer);

        if (layerKey === YOUR_POLYGONS) {
            yield call(hideLayer, polygonLabels);
        }
    } else {
        yield call(showLayer, featureLayer);

        if (layerKey === YOUR_POLYGONS) {
            yield call(showLayer, polygonLabels);
        }
    }
}

export function* loadDynamicLayer(parentLayer, selectedSublayers, layerFromMap, mapView) {
    const requestUrl = yield call(buildLayerURLParams, parentLayer.urlTemplate, selectedSublayers);
    if (layerFromMap) {
        layerFromMap.getMapUrl = requestUrl;
        yield call(refreshLayer, layerFromMap);
    } else {
        const layer = yield call(loadESRIDynamicLayerTemplate, {getMapUrl: requestUrl, id: parentLayer.layerKey});
        yield call(addLayerToMap, mapView, layer);
    }
}

export function* loadFeatureLayers(mapView) {
    const groupLayers = yield select(getGroupLayers);
    const organisationStyles = yield select(getOrganisationStyles);
    const supportedFeatures = yield select(getSupportedFeatures);

    const featureLayers = groupLayers.filter((layer) => {
        const isFeatureLayer = layer.layerType === 'feature';
        const isParentLayer = layer.parentLayerKey === null;
        const isIncludedIntoSupportedFeature = intersection(layer.supportedFeatures, supportedFeatures).length > 0;

        return isFeatureLayer && isParentLayer && isIncludedIntoSupportedFeature;
    }).toJS();

    for (const key in featureLayers) { // eslint-disable-line
        const {layerKey, layerId, shapeType, urlTemplate} = featureLayers[key];

        const outFields = ['objectid', 'style', 'title', 'description', 'reference', 'st_num', 'street', 'suburb', 'city', 'po_box', 'contact_person', 'contact_details', 'value1', 'date1', 'note1'];
        const renderer = yield call(getRendererByShapeType, organisationStyles, shapeType);
        const DEFAULT_PROPERTIES = {
            definitionExpression: '(STYLE is NOT NULL OR STYLE is NULL)', // TODO
            id: layerKey,
            layerId: layerId,
            minScale: 500000,
            outFields,
            popupEnabled: false,
            returnGeometry: true,
            title: layerKey && layerKey.replace('_', ' '),
            renderer,
            url: urlTemplate,
            visible: true,
        };

        const featureLayer = yield call(loadESRIFeatureLayer, DEFAULT_PROPERTIES);
        yield call(addLayerToMap, mapView, featureLayer);
    }
}

export function* loadGraphicsLayers(mapView) {
    const GRAPHICS_PROPERTIES = [
        {
            id: PROPERTY_GRAPHICS_LAYER,
            title: 'Property Graphics Layer',
        },
        {
            id: DRAW_AND_MEASURE_GRAPHICS_LAYER,
            title: 'Draw and Measure Graphics Layer',
        },
        {
            id: ADD_EDIT_YOUR_DATA_GRAPHICS_LAYER,
            title: 'Add Edit Your Data Graphics Layer',
        },
        {
            id: EXPORT_GRAPHICS_LAYER,
            title: 'Export Graphics Layer',
        },
        {
            id: LABELS_GRAPHICS_LAYER,
            title: 'Labels Graphics Layer',
        },
    ];

    const graphicsLayers = yield all(GRAPHICS_PROPERTIES.map((property) => call(loadESRIGraphicsLayer, property)));
    yield call(addManyLayerToMap, mapView, graphicsLayers);
}

export function* loadNavigationToolboxHandler(_, arcGIS = window.arcGIS) {
    // Preload Selected Dynamic Layers
    // TODO: Move filtering of layers (supported features) in reducer-level.
    const supportedFeatures = yield select(getSupportedFeatures);

    if (supportedFeatures.includes('CLIENT_QBE')) {
        const qbe = yield select(getLayerFromState, 'QBE');
        yield call(handleDynamicLayer, arcGIS.mapView, qbe);
    }

    // Graphics Layers //
    yield call(loadGraphicsLayers, arcGIS.mapView);
    yield call(loadPolygonLabels, arcGIS.mapView);

    // Feature Layers //
    yield call(loadFeatureLayers, arcGIS.mapView);
}

export function* loadPolygonLabels(mapView) {
    const polygonFeatureLayer = yield call(findLayerById, YOUR_POLYGONS, mapView);

    if (polygonFeatureLayer) {
        const geometryService = yield call(loadESRIGeometryService, {url: GEOMETRY_SERVICE_URL});
        const geometryEngine = yield call(loadESRIGeometryEngine, {noConstructor: true});

        const layerView = yield call(getFeatureLayerView, polygonFeatureLayer, mapView);
        const channel = yield call(watchLayerViewUpdatingChannel, [layerView, geometryService, geometryEngine]);
        yield takeLatest(channel, watchPolygonLabelsLayerViewHandler);
    }
}

export function* refreshLayersHandler() {
    const organisationStyles = yield select(getOrganisationStyles);

    yield put(refreshLayersFinished(organisationStyles));
}

export function* toggleMapLayer({payload: layerKey}, mapView = window.arcGIS.mapView) {
    try {
        yield put(showNotificationToast(true));
        const layer = yield select(getLayerFromState, layerKey);
        const layerFromMap = yield call(findLayerById, layer.layerKey, mapView);

        let layerHandlerFn;
        if (layer.layerType === TILE_LAYER) {
            layerHandlerFn = handleTileLayer;
        } else if (layer.layerType === DYNAMIC_LAYER) {
            layerHandlerFn = handleDynamicLayer;
        } else if (layer.layerType === FEATURE_LAYER) {
            layerHandlerFn = handleFeatureLayer;
        }

        yield call(layerHandlerFn, mapView, layer, layerFromMap);

        // Reposition hybrid basemap overlay (bottom-most)
        yield call(reorderLayer, mapView, AERIAL_OVERLAY_CL, 0);
    } catch (e) {
        console.log(e);
    } finally {
        yield put(showNotificationToast());
    }
}

export function* watchPolygonLabelsLayerViewHandler([layerView, geometryService, geometryEngine], arcGIS = window.arcGIS) {
    try {
        const labelGraphicsLayer = yield call(findLayerById, LABELS_GRAPHICS_LAYER, arcGIS.mapView);
        yield call(removeAllGraphicsLayerGraphics, labelGraphicsLayer);
        const addEditDrawAndMeasureActiveTool = yield select(getAddEditDrawAndMeasureActiveTool);
        const MIN_SCALE_TO_SHOW_POLYGON_LABELS = 30236.16;

        if (arcGIS.mapView.scale > MIN_SCALE_TO_SHOW_POLYGON_LABELS || addEditDrawAndMeasureActiveTool !== null) {
            return;
        }

        const query = layerView.createQuery();
        query.geometry = arcGIS.mapView.extent;
        query.outFields = ['*'];
        query.returnGeometry = true;

        const {features} = yield call(queryFeatures, layerView, query);
        const filteredFeatureResults = features.filter(({geometry}) => geometry && geometry.rings.length > 0);
        const featureResultGeometries = filteredFeatureResults.map(({geometry}) => geometry);

        const intersectGeometries = yield call(getIntersectGeometries, geometryEngine, featureResultGeometries, arcGIS.mapView.extent);
        const filteredIntersectGeometries = intersectGeometries.filter((geometry) => geometry && geometry.rings.length > 0);
        const labelPoints = yield call(getLabelPoints, geometryService, filteredIntersectGeometries);

        const organisationStyles = yield select(getOrganisationStyles);
        let labelGraphics = [];
        const storedLabelPoints = [...labelPoints];
        for (let i = 0; i < filteredFeatureResults.length; i++) {
            const {attributes: featureAttributes} = filteredFeatureResults[i];
            const labelPoint = labelPoints[i];

            let yoffset = 0;
            const nearestPointsInTheCurrentPoint = storedLabelPoints.filter((lp) => {
                const distance = geometryEngine.distance(labelPoint, lp);

                return distance >= 0 && distance <= 70;
            });

            if (nearestPointsInTheCurrentPoint.length > 0) {
                yoffset = (nearestPointsInTheCurrentPoint.length * 12);
            }

            storedLabelPoints.splice(storedLabelPoints.indexOf(labelPoint), 1);

            const style = organisationStyles.find(({shapeType, styleName}) => {
                return shapeType === POLY && styleName === featureAttributes.style;
            });

            const textSymbol = {
                color: convertOxNotationToHex(style ? style.outlineColour : '0x000000'),
                font: {
                    family: 'Ubuntu Light',
                    size: '13px',
                    weight: 'bold',
                },
                haloSize: 1,
                haloColor: 'white',
                text: featureAttributes.title,
                type: 'text',
                yoffset,
            };

            const labelGraphic = yield call(loadESRIGraphic, {
                geometry: labelPoint,
                symbol: textSymbol,
            });
            labelGraphics = [...labelGraphics, labelGraphic];
        }

        yield call(addManyGraphicsToGraphicsLayer, labelGraphics, labelGraphicsLayer);
    } catch (e) {
        console.log(e);
    }
}

// WATCHER //
export default function* watchLayers() {
    yield takeLatest(LOAD_NAVIGATION_TOOLS, loadNavigationToolboxHandler);
    yield takeLatest(REFRESH_LAYERS, refreshLayersHandler);
    yield takeLatest(TOGGLE_MAP_LAYER, toggleMapLayer);
}