/* eslint-disable react/display-name */

import React from 'react';
import PropTypes from 'prop-types';
import {Redirect, Route} from 'react-router';
import {useSelector} from 'react-redux';
import {getClaudToken, getTokenContents} from 'app/store/sagas/tokenSaga';

export const useTokenAuth = () => {
    const token = useSelector(getClaudToken);
    const tokenContents = useSelector(getTokenContents);

    const validUser = token && token.accessToken && token.validToken;
    const isAdmin = token.admin;

    return {
        validUser: !!validUser,
        isAdmin: !!isAdmin,
    };
};

export const AuthenticatedRoute = ({component: WrappedComponent, ...rest}) => {
    const { validUser } = useTokenAuth();
    return (
        <Route
            {...rest}
            render={() => (
                validUser ? <WrappedComponent {...rest} /> : <Redirect to="/"/>
            )}
        />
    );
};

AuthenticatedRoute.propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export const AdminRoute = ({component: WrappedComponent, render: componentRender, ...rest}) => {
    const { validUser, isAdmin } = useTokenAuth();
    const renderComp = WrappedComponent
        ? () => <WrappedComponent {...rest}/>
        : componentRender;

    return (
        <Route
            {...rest}
            render={
                !!validUser && !!isAdmin ?
                    renderComp :
                    validUser ?
                        () => <Redirect to="/map" /> :
                        () => <Redirect to="/admin-site" />
            }
        />
    );
};

AdminRoute.propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    render: PropTypes.func,
};