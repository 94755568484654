import {
    ATTEMPT_FETCH_TOKEN,
    SUCCEED_FETCH_TOKEN,
    FAIL_FETCH_TOKEN,
    ATTEMPT_SAVE_CODE,
    SET_LOGOUT_REDIRECT_URL,
    ATTEMPT_LOGOUT,
} from 'app/store/actions/tokenAction';
import pick from 'lodash/pick';

const initialState = {
    claudToken: {
        accessToken: null,
        refreshToken: null,
        pingFederateToken: null,
        error: null,
        admin: false,
        validToken: false,
    },
    tokenContents: {},
    code: null,
    loading: false,
    logoutRedirectUrl: null,
};

export default function tokenReducer(state = initialState, action = {}) {
    switch (action.type) {
    case ATTEMPT_FETCH_TOKEN:
        return {
            ...state,
            claudToken: {
                accessToken: null,
                refreshToken: null,
                pingFederateToken: null,
                error: null,
                admin: false,
                validToken: false,
            },
            tokenContents: {},
            code: null,
            loading: true,
        };
    case SUCCEED_FETCH_TOKEN: {
        const tokenContentKeys = ['exp', 'authorities'];
        return {
            ...state,
            claudToken: {
                accessToken: action.payload.claudToken.access_token,
                refreshToken: action.payload.claudToken.refresh_token,
                pingFederateToken: action.payload.claudToken.pingFederateToken,
                admin: action.payload.claudToken.admin,
                error: null,
                validToken: true,
            },
            tokenContents: {
                ...pick(action.payload.tokenContents, tokenContentKeys),
            },
            loading: false,
        };
    }
    case ATTEMPT_LOGOUT:
        return {
            ...state,
            claudToken: {
                accessToken: null,
                refreshToken: null,
                validToken: false,
                admin: false,
                error: null,
            },
            tokenContents: {},
            code: null,
            loading: false,
        };
    case FAIL_FETCH_TOKEN:
        return {
            ...state,
            claudToken: {
                accessToken: null,
                refreshToken: null,
                validToken: false,
                admin: false,
                error: action.payload,
            },
            tokenContents: {},
            code: null,
            loading: false,
        };
    case ATTEMPT_SAVE_CODE:
        return {
            ...state,
            code: action.payload,
        };
    case SET_LOGOUT_REDIRECT_URL:
        return { ...state, logoutRedirectUrl: action.payload};
    default:
        return state;
    }
}
