import React from 'react';
import PropTypes from 'prop-types';
import SectionForm from 'app/shared/SectionForm/SectionForm';
import FormFieldContainer, {DROPDOWN} from 'app/containers/FormFieldContainer';
import {symbolStyleOptions} from 'app/shared/Options';

import {Button} from 'semantic-ui-react';
import './AddEditAttributes.css';

function AddEditAttributes(props) {
    const {
        isDeleteDisabled,
        isMeridianUser,
        symbolStyleOptionsLocal,
        onSubmitGraphicAttributes,
        onDeleteGraphicAttributes,
    } = props;

    const customTypeOptions = symbolStyleOptionsLocal(props.customTypeOptions);

    const AddEditAttributesFieldsInfo = [
        {
            id: 'title',
            name: isMeridianUser ? 'Retailer' : 'Title',
            type: 'text',
        },
        {
            id: 'style',
            name: 'Custom Type',
            options: customTypeOptions,
            floating: true,
            type: 'dropdown',
        },
        {
            id: 'description',
            name: isMeridianUser ? 'Legacy Mobile' : 'Description',
            maxLength: 254,
            rows: 6,
            type: isMeridianUser ? 'text' : 'textArea',
        },
        {
            id: 'reference',
            name: isMeridianUser ? 'Price Cat' : 'Reference',
            type: 'text',
        },
        {
            id: 'st_num',
            name: isMeridianUser ? 'Road #' : 'Street #',
            type: 'text',
        },
        {
            id: 'street',
            name: isMeridianUser ? 'Road Name' : 'Street Name',
            type: 'text',
        },
        {
            id: 'suburb',
            name: 'Suburb',
            type: 'text',
        },
        {
            id: 'city',
            name: isMeridianUser ? 'Town Postcode' : 'City',
            type: 'text',
        },
        {
            id: 'po_box',
            name: isMeridianUser ? 'Region' : 'PO Box',
            type: 'text',
        },
        {
            id: 'contact_person',
            name: isMeridianUser ? 'Capacity' : 'Contact Person',
            type: 'text',
        },
        {
            id: 'contact_details',
            name: isMeridianUser ? 'Meter Cat' : 'Contact Details',
            type: 'text',
        },
        {
            id: 'value1',
            name: isMeridianUser ? 'ICP' : 'Value',
            type: 'text',
        },
        {
            id: 'date1',
            name: isMeridianUser ? 'Legacy Final Bill Date' : 'Date',
            placeholderText: 'Enter Date',
            type: 'date',
        },
        {
            id: 'note1',
            name: isMeridianUser ? 'Legacy Email' : 'Notes',
            maxLength: 254,
            rows: 6,
            type: isMeridianUser ? 'text' : 'textArea',
        },
    ];

    return (
        <SectionForm
            className="add-edit-attributes"
            title="Edit Details"
            onSubmit={onSubmitGraphicAttributes}
        >
            <>
                <div className="section__form-add-edit-attributes__fields">
                    {AddEditAttributesFieldsInfo.map(({type, id, name, ...rest}) => {
                        return (
                            <FormFieldContainer
                                {...rest}
                                key={id}
                                className={`field-${id}`}
                                name={id}
                                label={name}
                                path={['addEditDrawAndMeasure', 'selectedGraphicToolDetails', id]}
                                placeholder={`${type === DROPDOWN ? 'Select a' : 'Enter'} ${name}`}
                                type={type}
                            />
                        );
                    })}
                </div>
                <div className="section__form-add-edit-attributes__actions">
                    <Button
                        className="section__form-add-edit-attributes__actions__submit"
                        content="OK"
                        size="medium"
                        type="submit"
                    />
                    <Button
                        className="section__form-add-edit-attributes__actions__delete"
                        content="Delete"
                        disabled={isDeleteDisabled}
                        size="medium"
                        type="button"
                        onClick={onDeleteGraphicAttributes}
                    />
                </div>
            </>
        </SectionForm>
    );
}

AddEditAttributes.propTypes = {
    customTypeOptions: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.number,
            color: PropTypes.string,
            text: PropTypes.string,
            value: PropTypes.string,
            shapeType: PropTypes.string,
            symbolStyle: PropTypes.string,
        }),
    ),
    isDeleteDisabled: PropTypes.bool.isRequired,
    isMeridianUser: PropTypes.bool.isRequired,
    symbolStyleOptionsLocal: PropTypes.func.isRequired,
    onSubmitGraphicAttributes: PropTypes.func.isRequired,
    onDeleteGraphicAttributes: PropTypes.func.isRequired,
};

AddEditAttributes.defaultProps = {
    symbolStyleOptionsLocal: symbolStyleOptions,
};

export default AddEditAttributes;