import {takeLatest, call, put, select, fork, delay} from 'redux-saga/effects';
import {
    CANCEL_DRAWING,
    CHANGE_ACTIVE_TOOL,
    DELETE_GRAPHIC_ATTRIBUTES,
    SET_GRAPHIC_ATTRIBUTE,
    SUBMIT_GRAPHIC_ATTRIBUTES,
    changeActiveTool,
    changeActiveToolFinished,
    changeActiveToolFailed,
    deleteGraphicAttributesFinished,
    deleteGraphicAttributesFailed,
    resetGraphicAttribute,
    setCustomTypeGraphicAttribute,
    setGraphicAttributes,
    submitGraphicAttributesFinished,
    submitGraphicAttributesFailed,
} from 'app/store/actions/addEditDrawAndMeasureAction';
import {showRightBarPane} from 'app/store/actions/uiAction';

import {
    ACTIVE,
    AUTO,
    CANCEL,
    CREATE,
    COMPLETE,
    CURSOR_ADDING,
    CURSOR_UPDATE,
    CURSOR_UPDATING,
    GRAB,
    LINE,
    MOVE,
    METERS,
    PARCEL,
    POINT,
    POLY,
    POLYLINE,
    POLYGON,
    RESHAPE,
    START,
    TOOL_DRAW_MEASURE,
    UPDATE,
    VERTEX_ADD,
    addGraphicToMapView,
    addGraphicToGraphicsLayer,
    addManyGraphicsToGraphicsLayer,
    addMouseToolTip,
    focusMapView,
    geometryTextArea,
    geometryTextLength,
    geometryTextRadius,
    getRadius,
    getTotalPerimeterTextLength,
    getAtGraphics,
    removeAllGraphicsLayerGraphics,
    removeAllMapViewGraphics,
    removeGraphicsLayerGraphic,
    reorderGraphicsLayerGraphicsByPosition,
    setMapViewCursor,
} from 'app/store/sagas/map/drawAndMeasureSaga';
import {TOOL_EXPORT_DATA} from 'app/store/sagas/map/exportAndImportSaga';
import {TOOL_IDENTIFY_PAN, TOOL_IDENTIFY_MULTIPLE_FEATURES} from 'app/store/sagas/map/identifyFeaturesSaga';
import {findLayerById} from 'app/store/sagas/map/layerSaga';
import {getParentNCBId, getGraphicOptions, getNCBId} from 'app/store/sagas/map/uiSaga';
import {BASE_QUERY_MAP_LAYER_BASE_URL, YOUR_POINTS, YOUR_POLYLINES, YOUR_POLYGONS} from 'app/api/configs/layersConfig';
import {loadESRIGraphic, loadESRIGeometryPolyline, loadESRIQuery, loadESRIQueryTask} from 'app/api/esri';

import {mapEventsChannel} from 'app/store/sagas/map/mapUtils';
import {convertHexToRgba, convertOxNotationToHex} from 'utils';
import isNull from 'lodash/isNull';
import capitalize from 'lodash/capitalize';
import trimEnd from 'lodash/trimEnd';

// UTILS //
export const TOOL_ADD_EDIT_YOUR_DATA = 'add-edit-your-data';
export const ADD_EDIT_YOUR_DATA_GRAPHICS_LAYER = 'ADD_EDIT_YOUR_DATA_GRAPHICS_LAYER';
const ADD = 'add';
const PUT = 'put';
const DELETE = 'delete';

export const getAddEditDrawAndMeasureActiveTool = (state) => state.getIn(['addEditDrawAndMeasure', 'activeTool']);
export const getToolboxActiveTool = (state) => state.getIn(['ui', 'activeToolButton']);
export const getOrganisationStyles = (state) => state.getIn(['config', 'organisationStyles', 'data']);
export const getRightBarPaneType = (state) => state.getIn(['ui', 'rightBarPaneType']);
export const getSelectedGraphicToolDetails = (state) => state.getIn(['addEditDrawAndMeasure', 'selectedGraphicToolDetails']).toJS();
export const getShowRightBarPane = (state) => state.getIn(['ui', 'showRightBarPane']);
export const getCompanyNCBId = (state) => state.getIn(['config', 'parentNCBId']);
export const getCorporateUser = (state) => state.getIn(['config', 'corporateUser']);
export const createDrawing = (sketch, tool, options) => sketch && sketch.create(tool, options);
export const completeDrawing = (sketch) => sketch.complete();
export const cancelDrawing = (sketch) => sketch.cancel();
export const deleteDrawing = (sketch) => sketch.delete();
export const destroyDrawing = (sketch) => sketch && sketch.destroy();
export const disableMidpoints = (sketch) => sketch.activeComponent.set('enableMidpoints', false);
export const updateDrawing = (sketch, graphic, options) => sketch.update([graphic], options);
export const setARCGISProperty = (property, name, value) => property.set(name, value);
export const applyEdits = async (layer, features) => await layer.applyEdits(features);
export const mapViewHitTest = async (evt, mapView) => await mapView.hitTest(evt);
export const queryFeatures = async (layer, query) => await layer.queryFeatures(query);
export const executeQuery = async (task, query) => await task.execute(query);
export const getActiveToolFromGraphic = (graphic) => {
    if (graphic.geometry.type === 'point') {
        return 'point';
    } else if (graphic.geometry.type === 'polyline') {
        if (graphic.geometry.paths[0].length > 2) {
            return 'polyline';
        } else {
            return 'line';
        }
    } else if (graphic.geometry.type === 'polygon') {
        if (graphic.geometry.rings[0].length > 20) {
            return 'circle';
        } else {
            return 'polygon';
        }
    }
};
export const createDefinitionExpression = (organisationStyles, parentNCBId, shapeType) => {
    let definition = '';
    organisationStyles
        .filter((style) => style.shapeType === shapeType)
        .forEach((style) => {
            if (style.styleName === 'Default') {
                definition += 'STYLE is NULL OR ';
            } else {
                definition += `STYLE = '${style.styleName}' OR `;
            }
        });

    definition = trimEnd(definition, ' OR ');
    return (definition ? `(${definition})` : '');
};

export const getSymbol = (style, active = false) => {
    let type;
    let color = convertOxNotationToHex(style.colour);
    switch (style.shapeType) {
    case LINE:
        type = 'simple-line';
        break;
    case POLY:
        color = convertHexToRgba(color, style.alpha);
        type = 'simple-fill';
        break;
    default:
        type = 'simple-marker';
        break;
    }

    return {
        color,
        outline: {
            color: active ? '#DBF720' : convertOxNotationToHex(style.outlineColour),
            width: active ? 3 : 2,
        },
        size: style.size,
        style: style.symbolStyle,
        type,
        width: 3,
    };
};

export function* getConfigSymbol(activeStyle, type) {
    const styles = yield select(getOrganisationStyles);

    const style = styles.find(({styleName, shapeType}) => {
        return type.includes(shapeType) &&
            (styleName === activeStyle ||
                (isNull(activeStyle) && styleName === 'Default')
            );
    });

    return yield call(getSymbol, style, true);
}

// WORKERS //
export function* changeActiveToolHandler({payload: tool}, arcGIS = window.arcGIS) {
    try {
        yield put(changeActiveToolFinished(false));

        if (tool !== null) {
            yield call(focusMapView, arcGIS.mapView);
            let drawTool = tool;
            if (tool === LINE) {
                drawTool = POLYLINE;
            } else if (tool === PARCEL) {
                drawTool = POINT;
            }

            yield call(createDrawing, arcGIS.sketchVM, drawTool, {mode: 'click'});

            const channel = yield call(mapEventsChannel, [arcGIS.mapView, 'pointer-move', tool]);
            yield takeLatest(channel, cursorUpdateHandler);
        } else {
            const toolboxActiveTool = yield select(getToolboxActiveTool);
            const rightBarPaneType = yield select(getRightBarPaneType);

            if (toolboxActiveTool !== TOOL_ADD_EDIT_YOUR_DATA) {
                yield call(destroyDrawing, arcGIS.sketchVM);
                delete arcGIS.sketchVM;

                if (rightBarPaneType === TOOL_ADD_EDIT_YOUR_DATA) {
                    const graphicsLayer = yield call(findLayerById, ADD_EDIT_YOUR_DATA_GRAPHICS_LAYER, arcGIS.mapView);

                    yield put(showRightBarPane(false));
                    yield put(resetGraphicAttribute());
                    yield call(removeAllGraphicsLayerGraphics, graphicsLayer);
                    yield call(removeAllMapViewGraphics, arcGIS.mapView);
                }
            }
        }
    } catch (e) {
        console.log(e);
        yield put(changeActiveToolFailed(String(e)));
    }
}

export function* cancelGraphicHandler(_, arcGIS = window.arcGIS) {
    yield call(completeDrawing, arcGIS.sketchVM);
}

export function* cursorUpdateHandler([evt, tool], arcGIS = window.arcGIS) {
    const checkMapViewGraphicsLength = arcGIS.mapView.graphics.length;
    const isShowRightBarPane = yield select(getShowRightBarPane);

    if (!isShowRightBarPane) {
        yield call(removeAllMapViewGraphics, arcGIS.mapView);
        evt = {mapPoint: arcGIS.mapView.toMap(evt), view: arcGIS.mapView};

        let cursorStatus = CURSOR_UPDATING;
        if (checkMapViewGraphicsLength > 1) {
            cursorStatus = CURSOR_ADDING;
        }
        yield fork(addMouseToolTip, evt, tool, cursorStatus);
    }
}

export function* drawHandler([evt], arcGIS = window.arcGIS) {
    const {aborted, state, type} = evt;
    const graphicsLayer = yield call(findLayerById, ADD_EDIT_YOUR_DATA_GRAPHICS_LAYER, arcGIS.mapView);
    const newGraphic = evt.graphics ? evt.graphics[0] : evt.graphic;

    if (state === START && type === UPDATE) {
        yield put(showRightBarPane(true, TOOL_ADD_EDIT_YOUR_DATA));
    } else if ((state === COMPLETE && type === UPDATE) || type === DELETE) {
        yield put(showRightBarPane(false));

        if (!aborted) {
            yield put(changeActiveTool(null));
            yield put(resetGraphicAttribute());
            yield call(removeAllGraphicsLayerGraphics, graphicsLayer);
        }

        yield call(removeAllMapViewGraphics, arcGIS.mapView);
    } else if (state === CANCEL) {
        yield call(removeAllMapViewGraphics, arcGIS.mapView);
        yield call(removeAllGraphicsLayerGraphics, graphicsLayer);

        return;
    }

    const activeTool = yield select(getAddEditDrawAndMeasureActiveTool);
    if (newGraphic) {
        if (newGraphic.geometry.type === POINT && activeTool === PARCEL) {
            yield call(drawParcel, newGraphic, evt);
        } else if (newGraphic.geometry.type === POINT) {
            yield call(drawPoint, newGraphic, evt);
        } else if (newGraphic.geometry.type === POLYLINE) {
            yield call(drawPolyline, newGraphic, evt);
        } else if (newGraphic.geometry.type === POLYGON) {
            yield call(drawPolygon, newGraphic, evt);
        }
    }
}

export function* drawParcel(graphic, evt) {
    const {state, type} = evt;
    graphic.visible = false;

    if (type === CREATE && state === COMPLETE) {
        const queryOptions = {
            geometry: graphic.geometry,
            outFields: ['*'],
            returnGeometry: true,
            spatialRelationship: 'within',
        };
        const query = yield call(loadESRIQuery, queryOptions);

        const queryTaskOptions = {url: `${BASE_QUERY_MAP_LAYER_BASE_URL}/3`};
        const queryTask = yield call(loadESRIQueryTask, queryTaskOptions);
        const data = yield call(executeQuery, queryTask, query);
        const parcelGraphic = data.features[0];

        yield call(updateGraphicHandler, parcelGraphic);
    }
}

export function* drawPolygon(graphic, evt, arcGIS = window.arcGIS) {
    const {state, tool, type} = evt;

    if (state === ACTIVE || (state === COMPLETE && type === CREATE) || state === START) {
        yield call(removeAllMapViewGraphics, arcGIS.mapView);
        const isReshape = tool === RESHAPE;

        let graphics = [];
        let totalPerimeter = [];
        let graphicOptions = yield select(getGraphicOptions);
        const rings = graphic.geometry.rings[0];
        const isPolygon = rings.length < 40;

        if (isPolygon) {
            for (let i = 0; i < rings.length - 1; i++) {
                const startPoint = rings[i];
                const endPoint = rings[i + 1];
                const activeLine = [startPoint, endPoint];

                graphicOptions = graphicOptions.setIn(['polyline', 'geometry', 'paths'], activeLine);
                const activeLineGeometry = yield call(loadESRIGeometryPolyline, graphicOptions.polyline.geometry);
                const [activeTextGraphic, activeLengthValue] = yield call(geometryTextLength, activeLineGeometry, graphicOptions, isReshape, POLYGON);

                graphics = [...graphics, activeTextGraphic];
                totalPerimeter = [...totalPerimeter, activeLengthValue];
            }
        }

        if (rings.length > 3) {
            let totalPerimeterOrRadiusTextGraphic;
            if (isPolygon) {
                totalPerimeterOrRadiusTextGraphic = yield call(getTotalPerimeterTextLength, totalPerimeter, graphic.geometry, graphicOptions, true, isReshape);
            } else {
                const options = graphicOptions.setIn(['textMeasurement', 'symbol', 'yoffset'], -12);
                const innerPoint = graphic.geometry.centroid;
                const outerPoint = innerPoint.clone();
                outerPoint.set('x', rings[0][0]);
                outerPoint.set('y', rings[0][1]);

                const radius = yield call(getRadius, innerPoint, outerPoint, METERS);
                totalPerimeterOrRadiusTextGraphic = yield call(geometryTextRadius, graphic.geometry, options, radius);
            }

            const textAreaGraphic = yield call(geometryTextArea, graphic.geometry, graphicOptions);
            graphics = [...graphics, totalPerimeterOrRadiusTextGraphic, textAreaGraphic];
        }

        if (state === COMPLETE) {
            yield call(addManyGraphicsToGraphicsLayer, graphics, graphic.layer);
        } else {
            yield call(addManyGraphicsToGraphicsLayer, graphics, arcGIS.mapView.graphics);
        }
    }

    if (type === CREATE && state === COMPLETE) {
        yield call(updateGraphicHandler, graphic);
    } else if (type === UPDATE && state === COMPLETE) {
        const parentNCBId = yield select(getParentNCBId);
        const organisationStyles = yield select(getOrganisationStyles);
        const newDefinition = yield call(createDefinitionExpression, organisationStyles, parentNCBId, POLY);

        const featureLayer = yield call(findLayerById, YOUR_POLYGONS, arcGIS.mapView);
        yield call(setARCGISProperty, featureLayer, 'definitionExpression', newDefinition);
    }
}

export function* drawPolyline(graphic, evt, arcGIS = window.arcGIS) {
    const {state, toolEventInfo, type, tool} = evt;
    const activeTool = yield select(getAddEditDrawAndMeasureActiveTool);


    if (state === ACTIVE || state === START) {
        yield call(removeAllMapViewGraphics, arcGIS.mapView);

        const toolEventInfoType = toolEventInfo && toolEventInfo.type;
        const vertices = toolEventInfo && toolEventInfo.vertices;
        const vertexIndices = vertices && vertices[0].vertexIndex;
        const isReshape = tool === RESHAPE;
        const isMove = toolEventInfoType === MOVE;
        const isCursorUpdate = toolEventInfoType === CURSOR_UPDATE;
        const isVertexAdd = toolEventInfoType === VERTEX_ADD;

        if (activeTool === LINE && isVertexAdd && (vertexIndices && vertexIndices > 0)) {
            yield delay(10);
            yield call(completeDrawing, arcGIS.sketchVM);
        } else if (isCursorUpdate || isMove || isReshape) {
            const graphicOptions = yield select(getGraphicOptions);
            const [textGraphic] = yield call(geometryTextLength, graphic.geometry, graphicOptions, isReshape);
            yield call(addGraphicToGraphicsLayer, textGraphic, arcGIS.mapView.graphics);
        }
    }

    if (type === CREATE && state === COMPLETE) {
        yield call(updateGraphicHandler, graphic);
    } else if (type === UPDATE && state === START) {
        yield call(disableMidpoints, arcGIS.sketchVM);
    } else if (type === UPDATE && state === COMPLETE) {
        const parentNCBId = yield select(getParentNCBId);
        const organisationStyles = yield select(getOrganisationStyles);
        const newDefinition = yield call(createDefinitionExpression, organisationStyles, parentNCBId, LINE);

        const featureLayer = yield call(findLayerById, YOUR_POLYLINES, arcGIS.mapView);
        yield call(setARCGISProperty, featureLayer, 'definitionExpression', newDefinition);
    }
}

export function* drawPoint(graphic, evt, arcGIS = window.arcGIS) {
    const {state, type} = evt;

    if (type === CREATE && state === COMPLETE) {
        yield call(updateGraphicHandler, graphic);
    } else if (type === UPDATE && state === COMPLETE) {
        const parentNCBId = yield select(getParentNCBId);
        const organisationStyles = yield select(getOrganisationStyles);
        const newDefinition = yield call(createDefinitionExpression, organisationStyles, parentNCBId, POINT);

        const featureLayer = yield call(findLayerById, YOUR_POINTS, arcGIS.mapView);
        yield call(setARCGISProperty, featureLayer, 'definitionExpression', newDefinition);
    }
}

export function* deleteGraphicHandler(_, arcGIS = window.arcGIS) {
    const graphicsLayer = yield call(findLayerById, ADD_EDIT_YOUR_DATA_GRAPHICS_LAYER, arcGIS.mapView);

    const graphic = yield call(getAtGraphics, graphicsLayer, 0);
    yield call(applyEditsFeaturesHandler, graphic, DELETE);
}

export function* editGraphicHandler([evt], arcGIS = window.arcGIS) {
    try {
        const addEditDrawAndMeasureActiveTool = yield select(getAddEditDrawAndMeasureActiveTool);
        if (addEditDrawAndMeasureActiveTool !== null) {
            return;
        }

        const {results} = yield call(mapViewHitTest, evt, arcGIS.mapView);

        const feature = results.find(({graphic}) => [YOUR_POINTS, YOUR_POLYLINES, YOUR_POLYGONS].includes(graphic.layer.id));
        if (feature && !!feature.graphic) {
            const activeTool = yield call(getActiveToolFromGraphic, feature.graphic);
            const attributes = feature.graphic.attributes;
            yield put(setGraphicAttributes(attributes, activeTool));

            const featureLayer = feature.graphic.layer;
            const newDefinition = `${featureLayer.definitionExpression} AND NOT OBJECTID = ${feature.graphic.attributes.objectid}`;
            featureLayer.set('definitionExpression', newDefinition); // TODO wait till the new definition is applied

            yield call(updateGraphicHandler, feature.graphic);
        }
    } catch (e) {
        console.log(e);
    }
}

export function* hoverGraphicHandler([evt], arcGIS = window.arcGIS) {
    const addEditDrawAndMeasureActiveTool = yield select(getAddEditDrawAndMeasureActiveTool);
    const toolboxActiveTool = yield select(getToolboxActiveTool);
    if (addEditDrawAndMeasureActiveTool !== null
        || toolboxActiveTool === TOOL_DRAW_MEASURE
        || toolboxActiveTool === TOOL_EXPORT_DATA
        || toolboxActiveTool === TOOL_IDENTIFY_PAN
        || toolboxActiveTool === TOOL_IDENTIFY_MULTIPLE_FEATURES
    ) {
        return;
    }

    yield call(setMapViewCursor, GRAB, arcGIS.mapView);
    yield call(removeAllMapViewGraphics, arcGIS.mapView);
    const {results} = yield call(mapViewHitTest, evt, arcGIS.mapView);

    const feature = results.find(({graphic}) => graphic && graphic.layer && [YOUR_POINTS, YOUR_POLYLINES].includes(graphic.layer.id));
    if (feature && !!feature.graphic) {
        yield call(setMapViewCursor, AUTO, arcGIS.mapView);

        const graphicOptions = {
            geometry: feature.mapPoint,
            symbol: {
                type: 'text',
                color: '#000000',
                font: {
                    family: 'Ubuntu Light',
                    size: 10,
                },
                haloColor: '#FFFFFF',
                haloSize: '2px',
                text: feature.graphic.attributes.title,
                yoffset: -30,
            },
        };

        const textGraphic = yield call(loadESRIGraphic, graphicOptions);
        yield call(addGraphicToMapView, textGraphic, arcGIS.mapView);
    }
}

export function* setActiveSymbol(graphic, type) {
    const selectedGraphicToolDetails = yield select(getSelectedGraphicToolDetails);
    const symbol = yield call(getConfigSymbol, selectedGraphicToolDetails.style, type);
    yield call(setARCGISProperty, graphic, 'symbol', symbol);
}

export function* setGraphicAttributeHandler(action, arcGIS = window.arcGIS) {
    if (action.payload.name === 'style') {
        const graphicsLayer = yield call(findLayerById, ADD_EDIT_YOUR_DATA_GRAPHICS_LAYER, arcGIS.mapView);
        const newGraphic = yield call(getAtGraphics, graphicsLayer, 0);
        yield call(setActiveSymbol, newGraphic, newGraphic.geometry.type);

        yield call(removeGraphicsLayerGraphic, newGraphic, graphicsLayer);
        yield call(addGraphicToGraphicsLayer, newGraphic, graphicsLayer);
        yield call(reorderGraphicsLayerGraphicsByPosition, graphicsLayer, newGraphic, 0);
    }
}

export function* setDefaultSymbol(sketchVM, type) {
    const styles = yield select(getOrganisationStyles);
    const selectedGraphicToolDetails = yield select(getSelectedGraphicToolDetails);
    const isEditSymbol = !!selectedGraphicToolDetails.objectid;

    const sortedStylesByAsc = styles.sort((s1, s2) => s1.id - s2.id);
    const defaultStyleByShapeType = sortedStylesByAsc.find(({shapeType, styleName}) => styleName === 'Default' && type.includes(shapeType));
    const stylesByShapeType = sortedStylesByAsc.find(({shapeType}) => type.includes(shapeType));
    const style = defaultStyleByShapeType ? defaultStyleByShapeType : stylesByShapeType;

    const defaultStyle = isEditSymbol ? selectedGraphicToolDetails.style : style.styleName;

    const symbol = yield call(getConfigSymbol, defaultStyle, type);
    yield call(setARCGISProperty, sketchVM, `update${capitalize(type)}Symbol`, symbol);

    if (!isEditSymbol && defaultStyle !== 'Default') {
        yield put(setCustomTypeGraphicAttribute(defaultStyle));
    }
}

export function* submitGraphicHandler(_, arcGIS = window.arcGIS) {
    let selectedGraphicDetails = yield select(getSelectedGraphicToolDetails);
    const ncbId = yield select(getNCBId);
    const graphicsLayer = yield call(findLayerById, ADD_EDIT_YOUR_DATA_GRAPHICS_LAYER, arcGIS.mapView);

    const method = selectedGraphicDetails.objectid ? PUT : ADD;
    const newGraphic = yield call(getAtGraphics, graphicsLayer, 0);
    const dateTime = new Date().toLocaleString().replace(',', '').replace(/:.. /, ' ');
    const isCorporateUser = yield select(getCorporateUser);
    const companyNCBId = isCorporateUser ? yield select(getCompanyNCBId) : null;
    if (selectedGraphicDetails.objectid) {
        selectedGraphicDetails = {
            ...selectedGraphicDetails,
            UPDATED_AT: dateTime,
            UPDATED_BY: ncbId,
            company_ncbid: companyNCBId,
        };
    } else {
        selectedGraphicDetails = {
            ...selectedGraphicDetails,
            CREATED_AT: dateTime,
            CREATED_BY: ncbId,
            company_ncbid: companyNCBId,
        };
    }

    newGraphic.set('attributes', {...selectedGraphicDetails});

    yield call(applyEditsFeaturesHandler, newGraphic, method);
}

export function* updateGraphicHandler(graphic, arcGIS = window.arcGIS) {
    yield call(setDefaultSymbol, arcGIS.sketchVM, graphic.geometry.type);

    const graphicsLayer = yield call(findLayerById, ADD_EDIT_YOUR_DATA_GRAPHICS_LAYER, arcGIS.mapView);
    yield call(removeAllGraphicsLayerGraphics, graphicsLayer);
    yield call(addGraphicToGraphicsLayer, graphic, graphicsLayer);

    yield call(updateDrawing, arcGIS.sketchVM, graphic, {tool: 'reshape'});

    arcGIS.sketchVM.activeComponent.handleSymbol.size = 8;
    arcGIS.sketchVM.activeComponent.handleSymbol.symbol = 'circle';
    arcGIS.sketchVM.activeComponent.handleHoverSymbol.size = 8;
    arcGIS.sketchVM.activeComponent.handleHoverSymbol.symbol = 'circle';
    arcGIS.sketchVM.activeComponent.handleSelectionSymbol.size = 8;
    arcGIS.sketchVM.activeComponent.handleSelectionSymbol.symbol = 'circle';
    arcGIS.sketchVM.activeComponent.midpointSymbol.size = 8;
    arcGIS.sketchVM.activeComponent.midpointSymbol.symbol = 'circle';
}

export function* applyEditsFeaturesHandler(graphic, method, arcGIS = window.arcGIS) {
    let features;
    if (method === ADD) {
        features = {addFeatures: [graphic]};
        yield call(completeDrawing, arcGIS.sketchVM);
    } else if (method === DELETE) {
        features = {deleteFeatures: [graphic]};
        yield call(deleteDrawing, arcGIS.sketchVM);
    } else if (method === PUT) {
        features = {updateFeatures: [graphic]};
        yield call(completeDrawing, arcGIS.sketchVM);
    }

    try {
        const type = graphic.geometry.type;
        const featureLayer = yield call(findLayerById, `YOUR_${type.toUpperCase()}S`, arcGIS.mapView);
        yield call(applyEdits, featureLayer, features);

        if (method === ADD || method === PUT) {
            yield put(submitGraphicAttributesFinished());
        } else if (method === DELETE) {
            yield put(deleteGraphicAttributesFinished());
        }
    } catch (e) {
        console.log(e);
        if (method === ADD || method === PUT) {
            yield put(submitGraphicAttributesFailed(String(e)));
        } else if (method === DELETE) {
            yield put(deleteGraphicAttributesFailed(String(e)));
        }
    }
}

// WATCHER //
export default function* watchAddEditDrawAndMeasure() {
    yield takeLatest(CANCEL_DRAWING, cancelGraphicHandler);
    yield takeLatest(CHANGE_ACTIVE_TOOL, changeActiveToolHandler);
    yield takeLatest(DELETE_GRAPHIC_ATTRIBUTES, deleteGraphicHandler);
    yield takeLatest(SET_GRAPHIC_ATTRIBUTE, setGraphicAttributeHandler);
    yield takeLatest(SUBMIT_GRAPHIC_ATTRIBUTES, submitGraphicHandler);
}