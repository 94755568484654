
export const BASE_URL = window.location.origin;
export const PROD_BASE_URL_INTERNAL = 'emap.app.corelogic.asia';
export const PROD_BASE_URL_NZ = 'emap.co.nz';

function getSegmentAPIKey() {
    if (window.location.origin.indexOf('emap-ui-dev') > 0) {
       return 'J9ncjMQ7RjuUOVrezckEDmHy8oVAsw28';
    } else if (window.location.origin.indexOf('emap-uat') > 0) {
       return 'QqO4Tx37w3733lWUWF0KH2Np7wL5WXna';
    } else if (isProd()) {
       return 'oDMwztMBBqjSvQOa8GJqvPIXK2ecfF0j';
    }
}

function getLaunchDarklyAPIKey () {
    if (window.location.origin.indexOf('emap-ui-dev') > 0) {
		return '5a1f93ede7b7400d461c03cd';
	} else if (window.location.origin.indexOf('emap-uat') > 0) {
		return '5a1f93c332fd110d47ea2cf7';
	} else if (isProd()) {
		return '5a1f93c332fd110d47ea2cf8';
	}
}

//To support IE
if (!String.prototype.startsWith) {
    String.prototype.startsWith = function(searchString, position){
        position = position || 0;
        return this.substr(position, searchString.length) === searchString;
    };
}

export function isProd() {
    return BASE_URL.includes(PROD_BASE_URL_INTERNAL) || BASE_URL.includes(PROD_BASE_URL_NZ);
}

export const getClaudLoginUrl = (isAdmin) => {
    const loginRoute = isAdmin ? `${BASE_URL}/admin-site` : BASE_URL;

    return isProd() ?
        `https://auth.corelogic.asia/as/authorization.oauth2?response_type=code&client_id=AX9187c74bJ2Wh3I8kkzBG96W49bF0Om&redirect_uri=${loginRoute}/&scope=openid` :
        `https://auth-uat.corelogic.asia/as/authorization.oauth2?response_type=code&client_id=AX9187c74bJ2Wh3I8kkzBG96W49bF0Om&redirect_uri=${loginRoute}/&scope=openid`;
};

export default {
    CLAUD_LOGOUT_URL: isProd() ? `https://auth.corelogic.asia/idp/startSLO.ping?LogoutType=SyncOnly&TargetResource=${BASE_URL}` 
        : `https://auth-uat.corelogic.asia/idp/startSLO.ping?LogoutType=SyncOnly&TargetResource=${BASE_URL}`,
    SEGMENT_API_KEY: getSegmentAPIKey(),
    LAUNCH_DARKLY_API_KEY: getLaunchDarklyAPIKey(),
    flagsToFetch: [
        'show-emap-nearmap-button',
    ],
};